<template>
  <div class="w-full">
    <div class="flex justify-between items-center w-full">
      <div class="flex justify-between items-center">
        <vs-icon
          class="float-left"
          icon="attach_file"
          size="medium"
          color="primary"
        />
        <p>{{ file.file_name }}</p>
      </div>
      <vs-button
        :icon="downloadIcon"
        :color="downloadButtonColor"
        @click="downloadFile()"
      >
      </vs-button>
    </div>
    <vs-progress
      class="mt-2"
      v-if="filePersent && filePersent != 100"
      :height="12"
      :percent="filePersent"
    ></vs-progress>
  </div>
</template>

<script>
import axios from "axios";

import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      request: axios.CancelToken.source(),
      filePersent: 0,
      downloadIcon: "get_app",
      downloadButtonColor: "primary",
    };
  },
  props: ["file", "id"],
  methods: {
    downloadFile() {
      if (this.downloadButtonColor == "danger") {
        this.requestCancel();
        return;
      }
      this.downloadIcon = "clear";
      this.downloadButtonColor = "danger";

      if (typeof this.id == "undefined") {
        Api.get(
          API.BASEURL + API.DENTAL_FILES + this.file.id,
          this.handleDownloadFile
        );
      } else {
        Api.get(
          API.BASEURL + API.DENTALORDERS + this.id + "/download/",
          this.handleDownloadOrderFile
        );
      }
    },
    handleDownloadOrderFile(status, data) {
      if (status == 200) {
        if (data.plate_url != "") {
          this.request = axios.CancelToken.source();
          var downloadFileName = data.plate_url
            .toString()
            .split("plates/")[1]
            .split("?")[0];

          if (downloadFileName.split(".")[1] != "zip") {
            downloadFileName = this.order.id + "-" + downloadFileName;
          }

          axios({
            url: data.plate_url,
            method: "GET",
            cancelToken: this.request.token,
            responseType: "blob",
            onDownloadProgress: function (progressEvent) {
              this.filePersent = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then((response) => {
              this.downloadIcon = "get_app";
              this.downloadButtonColor = "primary";
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", downloadFileName);
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              if (error.message == "Cancel") {
                this.filePersent = 0;
                this.downloadIcon = "get_app";
                this.downloadButtonColor = "primary";
              }
            });
        } else {
          this.downloadIcon = "get_app";
          this.downloadButtonColor = "primary";
          this.$vs.notify({
            title: this.$t("Warning"),
            text: this.$t("File_Message_1"),
            color: "danger",
          });
        }
      } else if (status == 404) {
        this.downloadIcon = "get_app";
        this.downloadButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("File_Message_1"),
          color: "danger",
        });
      } else if (status == 500) {
        this.downloadIcon = "get_app";
        this.downloadButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("File_Message_1"),
          color: "danger",
        });
      }
    },
    handleDownloadFile(status, data) {
      if (status >= 200 && status < 300) {
        this.request = axios.CancelToken.source();
        axios({
          url: data.file,
          method: "GET",
          cancelToken: this.request.token,
          responseType: "blob",
          onDownloadProgress: function (progressEvent) {
            this.filePersent = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then((response) => {
            this.downloadIcon = "get_app";
            this.downloadButtonColor = "primary";
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", data.file_name);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            if (error.message == "Cancel") {
              this.filePersent = 0;
              this.downloadIcon = "get_app";
              this.downloadButtonColor = "primary";
            }
          });
      } else {
        this.downloadIcon = "get_app";
        this.downloadButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("File_Message_1"),
          color: "danger",
        });
      }
    },
    requestCancel() {
      this.request.cancel("Cancel");
      this.downloadIcon = "get_app";
      this.downloadButtonColor = "primary";
    },
  },
};
</script>

<style>
</style>
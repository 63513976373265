<template>
  <div>
    <div class="grid grid-cols-12">
      <vs-prompt
        :accept-text="$t('Accept')"
        :cancel-text="$t('Cancel')"
        title="Sipariş Onayı"
        @accept="orderConfirm()"
        :active.sync="askOrderConfirm"
        color="dark"
      >
        <div class="con-exemple-prompt">
          <span>
            {{ $t("Design_Message_7") }}
            {{ getTotalTreatmentCredit }} {{ $t("Design_Message_8") }}
          </span>
        </div>
      </vs-prompt>

      <vs-popup
        class="pdfClass"
        fullscreen
        :title="$t('Summary')"
        :active.sync="pdfEnable"
      >
        <vs-row vs-type="flex" vs-justify="center">
          <div
            ref="content"
            style="background-color: #fff; width: 720px; height: 1050px"
          >
            <!-- Sipariş Bilgisi  -->
            <div class="pdfBorder" style="display: inline-flex">
              <div class="pdfBorderRight" style="width: 130px">
                <p class="orderNoDateCreditPDF">
                  {{ $t("Order") }} {{ $t("No_Number") }}:
                  <span class="value"> {{ getOrder.id }} </span>
                </p>
              </div>
              <div class="pdfBorderRight" style="width: 110px">
                <p class="orderNoDateCreditPDF">
                  {{ $t("Credit") }}:
                  <span class="value"> {{ totalCredit() }} </span>
                </p>
              </div>
              <div class="pdfBorderRight" style="width: 300px">
                <p class="orderNoDateCreditPDF">
                  {{ $t("Patient") }}:
                  <span class="value"> {{ getOrder.patient }} </span>
                </p>
              </div>
              <div class="" style="width: 176px">
                <p class="orderNoDateCreditPDF">
                  {{ $t("Date") }}:
                  <span class="value">
                    {{ getRelativeTime(getOrder.created) }}
                  </span>
                </p>
              </div>
            </div>

            <!-- Siparis özet -->
            <vs-row>
              <vs-col class="p-2 pdfBorderWithoutTop" vs-w="8">
                <div
                  class="grid grid-cols-4"
                  style="font-weight: 700; font-size: 1rem"
                >
                  <div class="col-span-2">
                    {{ $t("Treatment") }} {{ $t("Type") }}
                  </div>
                  <div class="col-span-1">{{ $t("Quantity") }}</div>
                  <div class="col-span-1">
                    <div class="flex justify-between content-center">
                      <p>{{ $t("Credit") }}</p>
                    </div>
                  </div>
                </div>
                <vs-divider />
                <div
                  class="grid grid-cols-4 mt-1"
                  v-for="work in getDifferentWorks"
                  :key="work.teeth"
                >
                  <div class="col-span-2">
                    {{ work.name }}
                  </div>
                  <div class="col-span-1">
                    {{ work.unit }}
                  </div>
                  <div class="col-span-1">
                    {{ work.credit * work.unit }}
                  </div>
                </div>

                <vs-divider />
                <div
                  class="grid grid-cols-4"
                  style="font-weight: 700; font-size: 1rem"
                >
                  <div class="col-span-2">{{ $t("Total") }}</div>
                  <div class="col-span-1">
                    {{ totalUnit() }}
                  </div>
                  <div class="col-span-1">
                    {{ totalCredit() }}
                  </div>
                </div>
              </vs-col>

              <vs-col vs-w="4" class="pdfBorderWithoutTopLeft">
                <div class="positionRelative">
                  <div>
                    <dentalSvg :info="true" ref="dentalSvgRef" />
                  </div>
                  <div class="stackDiv flex items-center justify-center">
                    <vs-col style="width: fit-content">
                      <div
                        class="flex justify-start mb-2"
                        v-for="work in getDifferentWorks"
                        :key="work.code"
                      >
                        <vs-icon
                          size="10px"
                          :bg="work.color"
                          :color="work.color"
                        ></vs-icon>

                        <p class="ml-2" style="font-size: 0.6rem">
                          {{ work.name }}
                        </p>
                      </div>
                    </vs-col>
                  </div>
                </div>
              </vs-col>
            </vs-row>

            <!-- 3D - Zirkon - İmplant Listeleri -->
            <vs-row style="height: 275px">
              <vs-col vs-w="3" class="p-2 pdfBorderWithoutTop">
                <vs-row class="w-full" vs-type="flex-wrap">
                  <p class="text-center mb-2" style="font-weight: 700">
                    {{ $t("3D_Print") }}
                  </p>
                  <vs-col
                    vs-w="4"
                    v-for="teeth in get3DPrintsTeeth"
                    :key="teeth.teeth"
                  >
                    <p class="text-center">{{ teeth.teeth }}</p>
                  </vs-col>
                </vs-row>
              </vs-col>
              <vs-col vs-w="3" class="p-2 pdfBorderWithoutTopLeft">
                <vs-row class="w-full" vs-type="flex-wrap">
                  <p class="text-center mb-2" style="font-weight: 700">
                    {{ $t("Zirkon") }}
                  </p>
                  <vs-col
                    vs-w="4"
                    v-for="teeth in getZirkonTeeth"
                    :key="teeth.teeth"
                  >
                    <p class="text-center">{{ teeth.teeth }}</p>
                  </vs-col>
                </vs-row>
              </vs-col>
              <vs-col vs-w="6" class="pdfBorderWithoutTopLeft">
                <p class="text-center mb-2" style="font-weight: 700">
                  {{ $t("Implant") }}
                </p>
                <div
                  class="pl-2 pr-2"
                  :style="index % 2 == 0 ? '' : 'background-color: #ddd; '"
                  style="display: flex"
                  vs-type="flex-wrap"
                  v-for="(brandModel, index) in getImplantBrandModel"
                  :key="brandModel.brand + brandModel.model"
                >
                  <vs-col vs-w="6">
                    <p>{{ $t("Brand") }} : {{ brandModel.brand }}</p>
                    <p>{{ $t("Model") }} : {{ brandModel.model }}</p>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="6">
                    <vs-row>
                      <vs-col
                        vs-w="4"
                        v-for="teeth in brandModel.teeth"
                        :key="teeth.teeth"
                      >
                        <p class="text-center">{{ teeth }}</p>
                      </vs-col>
                    </vs-row>
                  </vs-col>
                </div>
              </vs-col>
            </vs-row>

            <!-- 3D - Zirkon- İmplant svgleri -->
            <vs-row>
              <vs-col vs-w="4" class="pdfBorderWithoutTop">
                <div class="positionRelative">
                  <div>
                    <dentalSvg
                      :justShow="get3DPrintsTeeth"
                      ref="dentalSvgRef"
                    />
                  </div>
                  <div class="stackDiv flex items-center justify-center">
                    <vs-col style="width: fit-content">
                      <div
                        class="flex justify-start mb-1"
                        v-for="work in get3DPrintsTreatments"
                        :key="work.code"
                      >
                        <vs-icon
                          size="10px"
                          :bg="work.color"
                          :color="work.color"
                        ></vs-icon>

                        <p class="ml-2" style="font-size: 10px">
                          {{ work.name }}
                        </p>
                      </div>
                    </vs-col>
                  </div>
                </div>
              </vs-col>
              <vs-col vs-w="4" class="pdfBorderWithoutTopLeft">
                <div class="positionRelative">
                  <div>
                    <dentalSvg :justShow="getZirkonTeeth" ref="dentalSvgRef" />
                  </div>
                  <div class="stackDiv flex items-center justify-center">
                    <vs-col style="width: fit-content">
                      <div
                        class="flex justify-start mb-1"
                        v-for="work in getZirkonTreatments"
                        :key="work.code"
                      >
                        <vs-icon
                          size="10px"
                          :bg="work.color"
                          :color="work.color"
                        ></vs-icon>

                        <p class="ml-2" style="font-size: 10px">
                          {{ work.name }}
                        </p>
                      </div>
                    </vs-col>
                  </div>
                </div>
              </vs-col>
              <vs-col vs-w="4" class="pdfBorderWithoutTopLeft">
                <div class="positionRelative">
                  <div>
                    <dentalSvg :justShow="getImplantTeeth" ref="dentalSvgRef" />
                  </div>
                  <div class="stackDiv flex items-center justify-center">
                    <vs-col style="width: fit-content">
                      <div
                        class="flex justify-start mb-1"
                        v-for="work in getImplantTreatments"
                        :key="work.code"
                      >
                        <vs-icon
                          size="10px"
                          :bg="work.color"
                          :color="work.color"
                        ></vs-icon>

                        <p class="ml-2" style="font-size: 10px">
                          {{ work.name }}
                        </p>
                      </div>
                    </vs-col>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-row>
      </vs-popup>
      <vs-popup
        :title="$t('Treatment_Credits')"
        :active.sync="popupActiveTreatment"
      >
        <treatments />
      </vs-popup>
      <div class="summaryCols col-span-12 lg:col-span-4 md:col-span-6 m-1 p-2">
        <p class="orderNoDateCredit">
          {{ $t("Order") }} {{ $t("No_Number") }}:
          <span class="value"> {{ getOrder.id }} </span>
        </p>
        <p class="orderNoDateCredit">
          {{ $t("Date") }}:
          <span class="value"> {{ getRelativeTime(getOrder.created) }} </span>
        </p>
        <p class="orderNoDateCredit">
          {{ $t("Patient") }}:
          <span class="value"> {{ getOrder.patient }} </span>
        </p>
        <p class="orderNoDateCredit">
          {{ $t("Credit") }}: <span class="value"> {{ totalCredit() }} </span>
        </p>

        <div
          class="grid grid-cols-4 mt-12"
          style="font-weight: 700; font-size: 1.2rem"
        >
          <div class="col-span-2">{{ $t("Treatment") }} {{ $t("Type") }}</div>
          <div class="col-span-1">{{ "Quantity" }}</div>
          <div class="col-span-1">
            <div class="flex justify-between content-center">
              <p>{{ $t("Credit") }}</p>
              <vs-icon
                style="cursor: pointer"
                icon="info"
                size="20px"
                @click="popupActiveTreatment = true"
              ></vs-icon>
            </div>
          </div>
        </div>
        <vs-divider />
        <div
          class="grid grid-cols-4 mt-4"
          v-for="work in getDifferentWorks"
          :key="work.teeth"
        >
          <div class="col-span-2">
            {{ work.name }}
          </div>
          <div class="col-span-1">
            {{ work.unit }}
          </div>
          <div class="col-span-1">
            {{ work.credit * work.unit }}
          </div>
        </div>

        <vs-divider />
        <div
          class="grid grid-cols-4 mt-6"
          style="font-weight: 700; font-size: 1.2rem"
        >
          <div class="col-span-2">{{ $t("Total") }}</div>
          <div class="col-span-1">
            {{ totalUnit() }}
          </div>
          <div class="col-span-1">
            {{ totalCredit() }}
          </div>
        </div>
      </div>
      <div class="summaryCols col-span-12 lg:col-span-4 md:col-span-6 m-1 p-2">
        <div class="positionRelative">
          <div>
            <dentalSvg :info="true" ref="dentalSvgRef" />
          </div>
          <div class="stackDiv flex items-center justify-center">
            <vs-col style="width: fit-content">
              <div
                class="flex justify-start mb-2"
                v-for="work in getDifferentWorks"
                :key="work.code"
              >
                <vs-icon
                  size="20px"
                  :bg="work.color"
                  :color="work.color"
                ></vs-icon>

                <p class="ml-2">{{ work.name }}</p>
              </div>
            </vs-col>
          </div>
        </div>
      </div>
      <div class="summaryCols col-span-12 lg:col-span-4 md:col-span-6 m-1 p-2">
        <div class="flex justify-center buttonsDiv mb-8">
          <vs-button
            class="leftButton"
            :type="filterButtonActive == '3D' ? 'filled' : 'border'"
            @click="sendFilter('3D')"
          >
            {{ $t("3D_Print") }}
          </vs-button>
          <vs-button
            :type="filterButtonActive == 'Zirkon' ? 'filled' : 'border'"
            @click="sendFilter('Zirkon')"
          >
            {{ $t("Zirkon") }}
          </vs-button>
          <vs-button
            class="rightButton"
            :type="filterButtonActive == 'Implant' ? 'filled' : 'border'"
            @click="sendFilter('Implant')"
          >
            {{ $t("Implant") }}
          </vs-button>
        </div>
        <div v-if="filterButtonActive == ''">
          <p v-if="getOrder.note" class="orderNoDateCredit">
            {{ $t("Note") }} :
          </p>
          <p v-if="getOrder.note" class="p-2" style="font-weight: 700">
            {{ getOrder.note }}
          </p>
          <p v-if="getOrder.order_files.length" class="orderNoDateCredit">
            {{ $t("Sent Files") }} :
          </p>
          <VuePerfectScrollbar
            class="scroll-area"
            :settings="filesScrollSettings"
          >
            <vs-row
              class="mt-2 mb-2"
              vs-type="flex"
              vs-align="center"
              :key="file.id"
              v-for="file in getOrder.order_files"
            >
              <downloadFile :file="file" />
            </vs-row>
          </VuePerfectScrollbar>
        </div>
        <div v-if="filterButtonActive == '' && getOrder.order_status == 'R'">
          <p v-if="getOrder.order_files.length" class="orderNoDateCredit mt-10">
            {{ $t("Incoming") }} {{ $t("Files") }} :
          </p>
          <vs-row
            class="mt-2 mb-2 pt-2 pb-2"
            vs-type="flex"
            vs-align="center"
            style="
              border: 1px solid #efefef;
              border-radius: 5px;
              box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.4);
            "
          >
            <downloadFile
              :id="getOrder.id"
              :file="{ file_name: getOrder.id }"
            />
          </vs-row>
        </div>

        <div v-if="filterButtonActive == '3D'">
          <VuePerfectScrollbar
            class="scroll-area"
            :settings="filesScrollSettings"
          >
            <vs-row
              v-for="teeth in get3DPrintsTeeth"
              :key="teeth.teeth"
              vs-type="flex"
              vs-align="center"
            >
              <p class="p-2 summaryCols mt-2 w-full" style="font-weight: 600">
                {{ $t("Teeth") }} : {{ teeth.teeth }}
              </p>
            </vs-row>
            <p
              class="p-2"
              style="font-weight: 800"
              v-if="get3DPrintsTeeth.length == 0"
            >
              {{ $t("Dental_Message_3") }}
            </p>
          </VuePerfectScrollbar>
        </div>
        <div v-if="filterButtonActive == 'Zirkon'">
          <VuePerfectScrollbar
            class="scroll-area"
            :settings="filesScrollSettings"
          >
            <vs-row
              v-for="teeth in getZirkonTeeth"
              :key="teeth.teeth"
              vs-type="flex"
              vs-align="center"
            >
              <p class="p-2 summaryCols mt-2 w-full" style="font-weight: 600">
                {{ $t("Teeth") }} : {{ teeth.teeth }}
              </p>
            </vs-row>
            <p
              class="p-2"
              style="font-weight: 800"
              v-if="getZirkonTeeth.length == 0"
            >
              {{ $t("Dental_Message_4") }}
            </p>
          </VuePerfectScrollbar>
        </div>
        <div v-if="filterButtonActive == 'Implant'">
          <VuePerfectScrollbar
            class="scroll-area"
            :settings="filesScrollSettings"
          >
            <vs-row
              v-for="teeth in getImplantTeeth"
              :key="teeth.teeth"
              vs-type="flex"
              vs-align="center"
              style="font-weight: 600"
              class="summaryCols mt-2"
            >
              <p class="p-2">{{ $t("Teeth") }} }} : {{ teeth.teeth }}</p>
              <p v-if="teeth.implant" class="p-2">
                {{ $t("Brand") }} {{ teeth.implant_brand }} <br />
                {{ $t("Model") }} : {{ teeth.implant_model }}
              </p>
            </vs-row>
            <p
              class="p-2"
              style="font-weight: 800"
              v-if="getImplantTeeth.length == 0"
            >
              {{ $t("Dental_Message_5") }}
            </p>
          </VuePerfectScrollbar>
        </div>
      </div>
    </div>
    <vs-button @click="exportPDF" class="mt-2"> {{ $t("Export") }} </vs-button>
  </div>
</template>

<script>
import API from "@/api/config";
import Api from "@/api/api";

import moment from "moment";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

import dentalSvg from "./DentalSvg.vue";
import treatments from "./Treatments";
import downloadFile from "./DownloadDentalFile";

export default {
  data() {
    return {
      delayDoImage: 500,
      pdfEnable: false,
      filterButtonActive: "",
      askOrderConfirm: false,
      popupActiveTreatment: false,
      filesScrollSettings: {
        maxScrollbarLength: 100,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    getWorkToDo() {
      return this.$store.getters["dental/getWorkToDo"];
    },
    getOrder() {
      return this.$store.getters["dental/getDentalOrder"];
    },
    getWorks() {
      return this.$store.getters["dental/getDentalWorks"];
    },
    getDifferentWorks() {
      return this.$store.getters["dental/getDifferentWorks"];
    },
    getTotalTreatmentCredit() {
      return this.$store.getters["dental/getTotalTreatmentCredit"];
    },
    getOrderItems() {
      return this.$store.getters["dental/getDentalOrderItems"];
    },
    get3DPrintsTeeth() {
      return this.$store.getters["dental/get3DPrintsTeeth"];
    },
    getZirkonTeeth() {
      return this.$store.getters["dental/getZirkonTeeth"];
    },
    getImplantTeeth() {
      return this.$store.getters["dental/getImplantTeeth"];
    },
    get3DPrintsTreatments() {
      return this.$store.getters["dental/get3DPrintsTreatments"];
    },
    getZirkonTreatments() {
      return this.$store.getters["dental/getZirkonTreatments"];
    },
    getImplantTreatments() {
      return this.$store.getters["dental/getImplantTreatments"];
    },
    getImplantBrandModel() {
      return this.$store.getters["dental/getImplantBrandModel"];
    },
  },
  components: {
    dentalSvg,
    treatments,
    VuePerfectScrollbar,
    downloadFile,
  },
  methods: {
    exportPDF() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      this.pdfEnable = true;
      var that = this;

      setTimeout(() => {
        domtoimage
          .toPng(this.$refs.content)
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            const doc = new jsPDF({
              orientation: "portrait",
            });
            doc.addImage(img, "JPEG", 10, 10);
            const date = new Date();
            const filename = that.getOrder.id + ".pdf";
            doc.save(filename);
            that.pdfDownloaded();
          })
          .catch(function (error) {
            that.$vs.loading.close();
            that.exportPDF();
          });
      }, this.delayDoImage);
    },
    pdfDownloaded() {
      this.pdfEnable = false;
      this.$vs.loading.close();
    },
    sendFilter(filter) {
      if (filter == "3D") {
        if (this.filterButtonActive != "3D") {
          this.filterButtonActive = "3D";
          this.$refs.dentalSvgRef.controlInfo("3D");
        } else {
          this.filterButtonActive = "";
          this.$refs.dentalSvgRef.controlInfo("");
        }
      } else if (filter == "Zirkon") {
        if (this.filterButtonActive != "Zirkon") {
          this.filterButtonActive = "Zirkon";
          this.$refs.dentalSvgRef.controlInfo("Zirkon");
        } else {
          this.filterButtonActive = "";
          this.$refs.dentalSvgRef.controlInfo("");
        }
      } else if (filter == "Implant") {
        if (this.filterButtonActive != "Implant") {
          this.filterButtonActive = "Implant";
          this.$refs.dentalSvgRef.controlInfo("Implant");
        } else {
          this.filterButtonActive = "";
          this.$refs.dentalSvgRef.controlInfo("");
        }
      }
    },
    orderConfirmRequest() {
      this.askOrderConfirm = true;
    },
    orderConfirm() {
      Api.post(
        API.BASEURL + API.DENTALORDERS + this.getOrder.id + "/send/",
        this.handleOrderConfirm
      );
    },
    handleOrderConfirm(status, data) {
      if (status >= 200 && status < 300) {
        this.$router.push("/dental_orders").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Uyarı",
          text: "Siparişi onaylamak için yeterli krediniz yok!",
        });
      }
    },
    getRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YY - HH:mm");
    },
    totalUnit() {
      var total = 0;

      this.getDifferentWorks.forEach((element) => {
        total += element.unit;
      });

      return total;
    },
    totalCredit() {
      var total = 0;

      this.getDifferentWorks.forEach((element) => {
        total += element.credit * element.unit;
      });

      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
.pdfClass {
  .orderNoDateCreditPDF .value {
    color: #000;
  }
  .grid {
    color: #000;
  }
  .vs-row {
    color: #000;
  }
}
.pdfBorder {
  border: 2px solid #aaa;
}
.pdfBorderRight {
  border-right: 2px solid #aaa;
}
.pdfBorderWithoutTop {
  border-left: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
  border-right: 2px solid #aaa;
}
.pdfBorderWithoutTopLeft {
  border-bottom: 2px solid #aaa;
  border-right: 2px solid #aaa;
}
.buttonsDiv {
  .vs-button {
    padding: 0.5rem 1rem !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .leftButton {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .rightButton {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.scroll-area {
  position: relative;
  width: auto;
  max-height: 400px;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.4);
}
.orderNoDateCredit {
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
  color: #c89e82;
  .value {
    color: #555555;
  }
}
.orderNoDateCreditPDF {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  font-size: 14px;
  font-weight: 600;
  color: #c89e82;
  .value {
    color: #555555;
  }
}
.positionRelative {
  position: relative;
}
.stackDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.summaryCols {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.pdfCols {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
}
</style>

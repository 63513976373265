<template>
  <div>
    <div class="flex justify-between content-center p-2" v-for="treatment in treatments" :key="treatment.code" :style="'background-color:'+ treatment.color">
        
      <p class="text-white" style="font-weight:600">
        {{ treatment.name }}
      </p>
      <p class="text-white" style="font-weight:600">
          {{treatment.credit}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    treatments() {
      return this.$store.getters["dental/getDentalWorks"];
    },
  },
};
</script>

<style>
</style>
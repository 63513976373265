<template>
  <transition name="fade">
    <div>
      <vx-card class="p-2">
        <div>
          <div class="flex justify-between mb-4">
            <p class="fileName">{{ file.name }}</p>
            <p class="fileName text-center" style="min-width: 70px">
              {{ bytesToSize(file.size) }}
            </p>
          </div>

          <vs-progress
            class="mb-4"
            :height="12"
            :percent="filePersent"
            :color="fileUploadFailed ? 'danger' : 'primary'"
          ></vs-progress>

          <div class="flex items-center justify-between">
            <p v-if="!fileUploadSuccess"></p>
            <p v-if="fileUploadSuccess" style="color: green; font-weight: 600">
              {{ $t("Upload_SuccessFull") }}
            </p>
            <vs-button
              v-if="!fileUploadSuccess"
              :disabled="fileUploadFailed || filePersent == 100"
              color="danger"
              @click="requestCancel()"
              >{{ $t("Cancel") }}</vs-button
            ><vs-button v-else color="danger" @click="requestDelete()">{{
              $t("Delete")
            }}</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </transition>
</template>

<script>
import API from "@/api/config";
import Api from "@/api/api";
import axios from "axios";

export default {
  data() {
    return {
      uploadURL: "",
      filePersent: 0,
      request: axios.CancelToken.source(),
      fileUploadFailed: false,
      fileUploadSuccess: false,
      fileId: "",
    };
  },
  props: ["file"],
  methods: {
    requestDelete() {
      Api.delete(
        API.BASEURL + API.DENTAL_FILES + this.fileId + "/",
        this.handleRequestDelete
      );
    },
    handleRequestDelete(status, data) {
      if (status == 204) {
        this.$emit("removeFile", this.file.name);
        this.$destroy();
      }
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    uploadFile() {
      this.uploadURL = API.BASEURL + API.DENTAL_FILES;
      let formData = new FormData();
      formData.append("file", this.file);

      this.request = axios.CancelToken.source();

      axios
        .request({
          method: "post",
          url: this.uploadURL,
          data: formData,
          timeout: 1000000,
          cancelToken: this.request.token,
          headers: {
            authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.filePersent = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.fileUploadSuccess = true;
            this.fileId = response.data.id;
            this.$emit("uploadedFile", this.fileId);
          }
        })
        .catch((error) => {
          this.filePersent = -1;
          this.fileUploadFailed = true;
        });
    },
    requestCancel() {
      this.request.cancel("Cancel");
    },
  },
  created() {
    this.uploadFile();
  },
  destroyed() {
    this.requestCancel();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fileName {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<template>
  <div>
    <vx-card>
      <!-- TABS -->
      <div
        class="grid grid-cols-4 justify-items-center mb-4"
        v-if="
          getOrder.order_status != 'S' &&
          getOrder.order_status != 'P' &&
          getOrder.order_status != 'R'
        "
      >
        <div
          @click="changePage(0)"
          class="col-span-1 w-full"
          style="text-align: -webkit-center; cursor: pointer"
        >
          <div class="w-full" style="position: relative">
            <div
              class="divAralariLeft"
              style="
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              "
            ></div>
            <div :class="activeTab >= 1 ? 'divAralariRight' : ''"></div>
            <div
              :class="activeTab >= 0 ? 'iconDiv' : 'iconDivNotActive'"
              class="flex justify-center"
            >
              <img
                v-if="activeTab >= 0"
                src="@/assets/images/dental/hasta-1.png"
                class="tabImageStyle"
                alt=""
              />
              <img
                v-else
                src="@/assets/images/dental/hasta-2.png"
                class="tabImageStyle"
                alt=""
              />
            </div>
          </div>

          <p
            class="mt-1"
            style="font-weight: 500"
            :style="activeTab >= 0 ? 'color: #c89e82;' : 'color: #aaa'"
          >
            {{ $t("Patient_Selection") }}
          </p>
        </div>
        <div
          :style="activeTab >= 1 ? '' : 'pointer-events: none !important'"
          @click="changePage(1)"
          class="col-span-1 w-full"
          style="text-align: -webkit-center; cursor: pointer"
        >
          <div class="w-full" style="position: relative">
            <div :class="activeTab >= 1 ? 'divAralariLeft' : ''"></div>
            <div :class="activeTab >= 2 ? 'divAralariRight' : ''"></div>
            <div
              :class="activeTab >= 1 ? 'iconDiv' : 'iconDivNotActive'"
              class="flex justify-center"
            >
              <img
                v-if="activeTab >= 1"
                src="@/assets/images/dental/is-secimi-1.png"
                class="tabImageStyle"
                alt=""
              />
              <img
                v-else
                src="@/assets/images/dental/is-secimi-2.png"
                class="tabImageStyle"
                alt=""
              />
            </div>
          </div>
          <p
            class="mt-1"
            style="font-weight: 500"
            :style="activeTab >= 1 ? 'color: #c89e82;' : 'color: #aaa'"
          >
            {{ $t("Treatment_Selection") }}
          </p>
        </div>
        <div
          :style="activeTab >= 2 ? '' : 'pointer-events: none !important'"
          @click="changePage(2)"
          class="col-span-1 w-full"
          style="text-align: -webkit-center; cursor: pointer"
        >
          <div class="w-full" style="position: relative">
            <div :class="activeTab >= 2 ? 'divAralariLeft' : ''"></div>
            <div :class="activeTab >= 3 ? 'divAralariRight' : ''"></div>

            <div
              :class="activeTab >= 2 ? 'iconDiv' : 'iconDivNotActive'"
              class="flex justify-center"
            >
              <img
                v-if="activeTab >= 2"
                src="@/assets/images/dental/data-upload-1.png"
                class="tabImageStyle"
                alt=""
              />
              <img
                v-else
                src="@/assets/images/dental/data-upload-2.png"
                class="tabImageStyle"
                alt=""
              />
            </div>
          </div>
          <p
            class="mt-1"
            style="font-weight: 500"
            :style="activeTab >= 2 ? 'color: #c89e82;' : 'color: #aaa'"
          >
            {{ $t("Upload_Scan_Data") }}
          </p>
        </div>
        <div
          :style="activeTab >= 3 ? '' : 'pointer-events: none !important'"
          @click="changePage(3)"
          class="col-span-1 w-full"
          style="text-align: -webkit-center; cursor: pointer"
        >
          <div class="w-full" style="position: relative">
            <div :class="activeTab >= 3 ? 'divAralariLeft' : ''"></div>
            <div
              :class="activeTab >= 3 ? 'divAralariRight' : ''"
              style="
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              "
            ></div>
            <div
              :class="activeTab >= 3 ? 'iconDiv' : 'iconDivNotActive'"
              class="flex justify-center"
            >
              <img
                v-if="activeTab >= 3"
                src="@/assets/images/dental/ozet-1.png"
                class="tabImageStyle"
                alt=""
              />
              <img
                v-else
                src="@/assets/images/dental/ozet-2.png"
                class="tabImageStyle"
                alt=""
              />
            </div>
          </div>
          <p
            class="mt-1"
            style="font-weight: 500"
            :style="activeTab >= 3 ? 'color: #c89e82;' : 'color: #aaa'"
          >
            {{ $t("Summary") }}
          </p>
        </div>
      </div>
      <!-- CONTENT -->
      <div>
        <patientSelection
          v-if="activeTab == 0"
          ref="patientSelection"
          @nextTab="nextPatientSelection"
        />
        <jobSelection v-if="activeTab == 1" />
        <uploadData
          v-if="activeTab == 2"
          ref="uploadData"
          @nextTab="nextUploadData"
        />
        <orderSummary v-if="activeTab == 3" ref="orderSummary" />
      </div>
      <!-- BUTTONS -->
      <div class="mt-4 flex justify-between">
        <vs-button
          v-if="
            (activeTab > 0 && getOrder.order_status == 'W') ||
            (activeTab > 0 && getOrder.order_status == 'N')
          "
          @click="activeTab--"
        >
          {{ $t("Back") }}
        </vs-button>
        <div v-if="activeTab == 0"></div>

        <vs-button v-if="activeTab != 3" @click="nextButton()">{{
          $t("Next")
        }}</vs-button>
        <vs-button
          v-if="activeTab == 3 && getOrder.order_status == 'N'"
          @click="nextButton()"
        >
          {{ $t("Confirm_Order") }}</vs-button
        >
      </div>
    </vx-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import patientSelection from "./components/PatientSelection";
import jobSelection from "./components/JobSelection";
import uploadData from "./components/UploadData";
import orderSummary from "./components/OrderSummary";

import API from "@/api/config";
import Api from "@/api/api";

export default {
  data() {
    return {
      jobSelectionHasChanged: false,
      activeTab: 0,
      jobSelectionRequest: false,
      uploadDataRequest: false,
    };
  },
  methods: {
    getTreatments() {
      Api.get(API.BASEURL + API.DENTAL_TREATMENTS, this.handleGetTreatments);
    },
    handleGetTreatments(status, data) {
      if (status >= 200 && status < 300) {
        this.$store.dispatch("dental/changeTreatments", data.results);
      }
    },
    changePage(page) {
      if (this.getOrder.order_status == "S") {
        return;
      }
      this.activeTab = page;
    },
    nextButton() {
      if (this.activeTab == 0) {
        this.controlPatientSelection();
      } else if (this.activeTab == 1) {
        this.sendDentalWorks();
      } else if (this.activeTab == 2) {
        this.controlUploadData();
      } else {
        this.orderConfirm();
      }
    },
    orderConfirm() {
      this.$refs.orderSummary.orderConfirmRequest();
    },
    controlUploadData() {
      this.$refs.uploadData.sendFiles();
    },
    nextUploadData() {
      this.getOrderDetail(this.getOrder.id);
      this.activeTab = 3;
    },
    sendDentalWorks() {
      if (
        this.getDentalWork.length == 0 &&
        this.getOrder.order_items.length == 0
      ) {
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("Dental_Message_15"),
        });
        return;
      }
      if (!this.jobSelectionHasChanged) {
        this.activeTab = 2;
        return;
      }
      this.deleteAllTreatment();
    },
    handleSendDentalWorks(status, data) {
      this.$store.dispatch("dental/changeOrder", {
        order_items: data,
      });

      if ((status) => 200 && status < 300) {
        this.sendTeethRelations();
      }
    },
    sendTeethRelations() {
      var params = {
        teeth_relation: this.getOrder.teeth_relation,
      };
      Api.patch(
        API.BASEURL + API.DENTALORDERS + this.getOrder.id + "/",
        this.handleSendTeethRelations,
        params
      );
    },
    handleSendTeethRelations(status, data) {
      if (status == 200) {
        this.jobSelectionRequest = true;
        this.activeTab = 2;
      }
    },
    deleteAllTreatment() {
      Api.delete(
        API.BASEURL +
          API.DENTALORDERS +
          this.getOrder.id +
          "/treatment/delete/",
        this.handleDeleteAllTreatment
      );
    },
    handleDeleteAllTreatment(status, data) {
      if (status >= 200 && status < 300) {
        var params = [];

        this.getDentalWork.forEach((element) => {
          params.push({
            teeth: element.teeth,
            treatment: element.work.code,
            material: element.materiel,
            implant: element.implant.implant,
            implant_brand: element.implant.brand,
            implant_model: element.implant.model,
          });
        });
        Api.post(
          API.BASEURL + API.DENTALORDERS + this.getOrder.id + "/treatment/",
          this.handleSendDentalWorks,
          params
        );
      }
    },
    nextPatientSelection() {
      this.activeTab = 1;
      setTimeout(() => {
        this.jobSelectionHasChanged = false;
      }, 500);
    },
    controlPatientSelection() {
      this.$refs.patientSelection.controlNextPage();
    },
    getOrderDetail(id) {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(
        API.BASEURL + API.DENTALORDERS + id + "/",
        this.handleGetOrderDetail
      );
    },
    handleGetOrderDetail(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        this.$store.dispatch("dental/changeOrder", data);

        if (data.order_status == "W") {
          if (data.order_items.length == 0) {
            this.activeTab = 1;
          } else {
            this.activeTab = 2;
          }
        } else if (
          data.order_status == "N" ||
          data.order_status == "S" ||
          data.order_status == "P" ||
          data.order_status == "R"
        ) {
          this.activeTab = 3;
        }
      }
    },
    initPage() {
      if (typeof this.$route.query.id != "undefined") {
        let id = this.$route.query.id;
        this.getOrderDetail(id);
      }
    },
  },
  computed: {
    getOrder() {
      return this.$store.getters["dental/getDentalOrder"];
    },
    getDentalWork() {
      return this.$store.getters["dental/getDentalWork"];
    },
    getTeethRelations() {
      return this.$store.getters["dental/getTeethRelations"];
    },
  },
  watch: {
    getDentalWork(newValue, oldValue) {
      this.jobSelectionHasChanged = true;
    },
    getTeethRelations(newValue, oldValue) {
      this.jobSelectionHasChanged = true;
    },
  },
  components: {
    FormWizard,
    TabContent,
    patientSelection,
    jobSelection,
    uploadData,
    orderSummary,
  },
  created() {
    this.getTreatments();
    this.initPage();
    setTimeout(() => {
      this.jobSelectionHasChanged = false;
    }, 500);
  },
  beforeDestroy() {
    this.$store.dispatch("dental/removeDentalOrder");
  },
};
</script>

<style lang="scss" scoped>
.tabImageStyle {
  width: 60px;
  height: 60px;
  z-index: 99;
}
.divAralariRight {
  position: absolute;
  height: 10px;
  width: 46%;
  background-color: #c89e82;
  bottom: 40%;
  left: 55%;
}
.divAralariLeft {
  position: absolute;
  height: 10px;
  width: 46%;
  background-color: #c89e82;
  bottom: 40%;
  right: 55%;
}
.iconDiv {
  color: #fff;
  padding: 10px;
  border-radius: 50%;
}
.iconDivNotActive {
  background-color: #fff;
  color: #aaaaaa;
  padding: 10px;
  border-radius: 50%;
}
.formBackground {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
</style>

<template>
  <div>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Delete File')"
      @accept="deleteFile"
      :active.sync="askDeleteFile"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> {{ $t("File_Message_2") }} </span>
      </div>
    </vs-prompt>
    <div
      class="cardDiv"
      :style="'background-color :' + background"
      style="font-weight: 600"
    >
      <div class="grid grid-cols-12 flex items-center">
        <!-- File Name -->
        <div class="col-span-12 xl:col-span-4 sm:col-span-6 flex sm:mb-2 mb-2">
          <vs-icon icon="picture_as_pdf" size="small" color="primary" />
          <p>
            {{ file.file_name }}
          </p>
        </div>
        <!-- File User -->
        <div class="col-span-12 xl:col-span-2 sm:col-span-6 sm:mb-0 mb-2">
          {{ file.user }}
        </div>
        <!-- File Not -->
        <div
          class="col-span-12 xl:col-span-4 sm:col-span-6 sm:mb-0"
          style="cursor: pointer"
        >
          <p
            v-if="!file.note && !updateEnable"
            @click="
              isPass = true;
              updateEnable = !updateEnable;
            "
            style="
              font-size: 1rem;
              color: #aaa;
              font-weight: 600;
              width: fit-content;
            "
          >
            {{ $t("Note") }}
          </p>
          <p
            v-if="!updateEnable"
            @click="
              isPass = true;
              updateEnable = !updateEnable;
            "
            style="font-weight: 600; width: fit-content"
          >
            {{ file.note }}
          </p>
          <vs-input
            autofocus
            v-else
            v-model="file.note"
            @click="isPass = true"
            @keyup.enter="
              isPass = true;
              updateNote();
            "
          />
        </div>
        <!-- Buttons -->
        <div class="col-span-12 xl:col-span-2 sm:col-span-6 justify-self-end">
          <vs-button
            v-if="updateEnable"
            @click="
              isPass = true;
              updateNote();
            "
          >
            {{ $t("Update") }}
          </vs-button>
          <vs-button
            style="z-index: 999"
            v-if="selectAvaible && !updateEnable"
            color="primary"
            @click="selectFile"
          >
            {{ $t("Add") }}
          </vs-button>
          <vs-button
            style="z-index: 999"
            v-if="!removeAvaible && !selectAvaible && !updateEnable"
            color="danger"
            @click="
              isPass = true;
              askDeleteFile = true;
            "
          >
            {{ $t("Delete") }}
          </vs-button>
          <vs-button
            v-if="removeAvaible && !updateEnable"
            color="warning"
            @click="
              isPass = true;
              removeFileFromOrder();
            "
          >
            {{ $t("Remove") }}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/config";
import Api from "@/api/api";

export default {
  data() {
    return {
      updateEnable: false,
      background: "white",
      isPass: false,
      askDeleteFile: false,
    };
  },
  props: ["file", "selectAvaible", "removeAvaible"],
  computed: {
    getOrder() {
      return this.$store.getters["dental/getDentalOrder"];
    },
  },
  methods: {
    removeFileFromOrder() {
      var params = {
        method: "remove",
        files: [this.file.id],
      };

      Api.put(
        API.BASEURL + API.DENTALORDERS + this.getOrder.id + "/files/",
        this.handleRemoveFileFromOrder,
        params
      );
    },
    handleRemoveFileFromOrder(status, data) {
      if (status >= 200 && status < 300) {
        this.$emit("fileRemovedFromOrder", data.files);
      }
    },
    selectFile() {
      this.$emit("selectFile", this.file.id);
    },
    deleteFile() {
      Api.delete(
        API.BASEURL + API.DENTAL_FILES + this.file.id + "/",
        this.handleDeleteFile
      );
    },
    handleDeleteFile(status, data) {
      if (status >= 200 && status < 300) {
        this.$vs.notify({
          color: "success",
          title: this.$t("File_Message_3"),
          text: this.file.file_name + " " + this.$t("File_Message_3"),
        });

        this.$emit("fileDeleted", this.file.id);
      }
    },
    handleUpdateNote(status, data) {
      if (status == 200) {
        this.updateEnable = !this.updateEnable;
      } else if (status == 404) {
      }
    },
    updateNote() {
      var params = {
        note: this.file.note,
      };
      Api.put(
        API.BASEURL + API.DENTAL_FILES + this.file.id + "/",
        this.handleUpdateNote,
        params
      );
    },
  },
};
</script>

<style>
.cardDiv {
  width: 100%;
  border-radius: 0.5rem;
  display: block;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vs-popup',{attrs:{"title":_vm.$t('Treatment_Credits'),"active":_vm.popupActiveTreatment},on:{"update:active":function($event){_vm.popupActiveTreatment=$event}}},[_c('treatments')],1),_c('div',{staticClass:"grid grid-cols-12"},[_c('div',{staticClass:"\n        col-span-12\n        xs:col-span-12\n        md:col-span-8\n        xl:col-span-4\n        jobCols\n        m-1\n        p-2\n      ",style:(_vm.getWorkToDo.credit == 0
          ? 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.6);'
          : 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);')},[_c('div',{staticClass:"flex justify-between content-center"},[_c('p',{staticClass:"tags p-2"},[_vm._v(_vm._s(_vm.$t("Crowns_Offsets")))]),_c('vs-icon',{staticClass:"mt-2 mr-2",staticStyle:{"cursor":"pointer"},attrs:{"icon":"info","size":"20px"},on:{"click":function($event){_vm.popupActiveTreatment = true}}})],1),_c('div',{staticClass:"\n          grid grid-cols-2\n          xs:grid-cols-1\n          sm:grid-cols-2\n          xl:grid-cols-1\n          xxl:grid-cols-2\n        "},[_c('div',{staticClass:"col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[0].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#80004d","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[0])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/anatomik-kron.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[0].name)+" ")])]),_c('div',{staticClass:"col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[1].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#d071a9","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[1])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/anatomik-altyapi.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[1].name)+" ")])]),_c('div',{staticClass:"col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[2].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#a926b9","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[2])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/kapson-altyapi.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[2].name)+" ")])])]),_c('p',{staticClass:"tags p-2"},[_vm._v(_vm._s(_vm.$t("Pontics")))]),_c('div',{staticClass:"\n          grid grid-cols-2\n          xs:grid-cols-1\n          sm:grid-cols-2\n          xl:grid-cols-1\n          xxl:grid-cols-2\n        "},[_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[3].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#0a890a","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[3])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/anatomik-ara-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[3].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[4].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#00b705","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[4])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/ara-govde-altyapi.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[4].name)+" ")])])]),_c('p',{staticClass:"tags p-2"},[_vm._v(_vm._s(_vm.$t("Inley_Onley")))]),_c('div',{staticClass:"\n          grid grid-cols-2\n          xs:grid-cols-1\n          sm:grid-cols-2\n          xl:grid-cols-1\n          xxl:grid-cols-2\n        "},[_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[5].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#1362f2","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[5])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/inlay-dolgu.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[5].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[6].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#1250be","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[6])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/altyapi-dolgu.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[6].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[7].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#5027fc","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[7])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/vener.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[7].name)+" ")])])]),_c('p',{staticClass:"tags p-2"},[_vm._v(_vm._s(_vm.$t("Basic_Units")))]),_c('div',{staticClass:"\n          grid grid-cols-2\n          xs:grid-cols-1\n          sm:grid-cols-2\n          xl:grid-cols-1\n          xxl:grid-cols-2\n        "},[_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[8].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#a24807","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[8])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/bar-ana-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[8].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[9].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#e8731f","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[9])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/bar-ara-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[9].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[10].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#022e3d","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[10])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/surgu.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[10].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[11].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#b87471","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[11])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/teleskop.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[11].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[12].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#95080f","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[12])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/all-on-x-ana-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[12].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[13].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#d34d53","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[13])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/all-on-x-ara-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[13].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[14].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#05555f","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[14])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/hibrit-ana-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[14].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[15].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#179bad","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[15])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/hibrit-ara-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[15].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[16].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#60521b","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[16])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/toronto-ana-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[16].name)+" ")])]),_c('div',{staticClass:"sm:col-span-1 p-2"},[_c('div',{staticClass:"vs-buttonCss flex items-center",class:_vm.works[17].name == _vm.getWorkToDo.name || _vm.getWorkToDo.name == ''
                ? ''
                : 'disabledButton',staticStyle:{"background-color":"#9f9675","color":"white"},on:{"click":function($event){return _vm.requestChangeWorkToDo(_vm.works[17])}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/dental/job_icons/toronto-ara-govde.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.works[17].name)+" ")])])])]),_c('div',{staticClass:"\n        col-span-12\n        xs:col-span-12\n        md:col-span-4\n        xl:col-span-3\n        jobCols\n        m-1\n        p-2\n      ",class:_vm.getWorkToDo.credit == 0 ? 'disabledDiv' : '',style:(_vm.getWorkToDo.credit != 0 && _vm.materiel == null
          ? 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.6);'
          : 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);')},[_c('p',{staticClass:"tags text-center mb-6"},[_vm._v(_vm._s(_vm.$t("Material_Selection")))]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"vx-row items-end justify-center"},[_c('div',{staticClass:"vx-col",class:_vm.materiel == 2 ? 'disabledButton' : ''},[_c('img',{staticClass:"m-4 -mb-2",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/dental/3d-print-active.png"),"alt":""},on:{"click":function($event){_vm.materiel = 1;
                _vm.changeMateriel();}}}),_c('div',[_c('vs-button',{attrs:{"color":"#000"},on:{"click":function($event){_vm.materiel = 1;
                  _vm.changeMateriel();}}},[_vm._v(" "+_vm._s(_vm.$t("3D_Print"))+" ")])],1)]),_c('div',{staticClass:"vx-col",class:_vm.materiel == 1 ? 'disabledButton' : ''},[_c('img',{staticClass:"m-4 -mb-2",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/dental/zirkon-active.png"),"alt":""},on:{"click":function($event){_vm.materiel = 2;
                _vm.changeMateriel();}}}),_c('div',{},[_c('vs-button',{attrs:{"color":"#000"},on:{"click":function($event){_vm.materiel = 2;
                  _vm.changeMateriel();}}},[_vm._v(" "+_vm._s(_vm.$t("Zirkon"))+" ")])],1)])]),_c('div',{staticClass:"grid grid-cols-1 justify-items-center"},[_c('v-select',{staticClass:"mt-4",staticStyle:{"max-width":"200px","min-width":"160px"},attrs:{"placeholder":"Implant Selection","options":_vm.implantOptions},model:{value:(_vm.hasImplant),callback:function ($$v) {_vm.hasImplant=$$v},expression:"hasImplant"}}),(_vm.hasImplant.id == true)?_c('div',{staticClass:"p-2 text-left justify-start"},[_c('vs-input',{staticClass:"mb-2",attrs:{"placeholder":_vm.$t('Brand')},on:{"change":function($event){return _vm.sendImplantChangeRequest()}},model:{value:(_vm.implantBrand),callback:function ($$v) {_vm.implantBrand=$$v},expression:"implantBrand"}}),_c('vs-input',{attrs:{"placeholder":_vm.$t('Model')},on:{"change":function($event){return _vm.sendImplantChangeRequest()}},model:{value:(_vm.implantModel),callback:function ($$v) {_vm.implantModel=$$v},expression:"implantModel"}})],1):_vm._e()],1)])]),_c('div',{staticClass:"col-span-12 xs:col-span-12 xl:col-span-5 jobCols m-1 p-2",class:_vm.materiel == null ? 'disabledDiv' : '',style:(_vm.materiel == null
          ? 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);'
          : 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.6);')},[_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"tags"},[_vm._v(_vm._s(_vm.$t("Treatment_Definition")))]),_c('p',{staticClass:"tags credit"},[_vm._v(_vm._s(_vm.$t("Credit"))+": "+_vm._s(_vm.totalCredit()))])]),_c('div',{staticClass:"grid grid-cols-12",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"\n            col-span-8\n            xs:col-span-12\n            sm:col-span-8\n            md:col-span-8\n            xl:col-span-12\n            xxl:col-span-8\n          ",class:_vm.controlMaterielAndImpland() ? '' : 'disabledDiv',staticStyle:{"z-index":"99"}},[_c('dentalSvg')],1),(_vm.treatmentAreaRight)?_c('div',{staticClass:"\n            col-span-4\n            xs:col-span-12\n            sm:col-span-4\n            md:col-span-4\n            xl:col-span-12\n            xxl:col-span-4\n            flex\n            items-center\n          ",staticStyle:{"height":"50%"}},[_c('vs-col',_vm._l((_vm.getSelectedWorks),function(work){return _c('vs-row',{key:work.code,staticClass:"w-full mb-2",attrs:{"vs-type":"flex","vs-align":"center","vs-justify":"start"}},[_c('vs-icon',{attrs:{"size":"20px","bg":work.color,"color":work.color}}),_c('p',{staticClass:"ml-2 teethShowWork"},[_vm._v(_vm._s(work.name))])],1)}),1)],1):_c('div',{staticClass:"stackDiv flex items-center justify-center"},[_c('vs-col',{staticStyle:{"width":"fit-content"}},_vm._l((_vm.getSelectedWorks),function(work){return _c('div',{key:work.code,staticClass:"flex justify-start mb-2"},[_c('vs-icon',{attrs:{"size":"20px","bg":work.color,"color":work.color}}),_c('p',{staticClass:"ml-2 teethShowWork"},[_vm._v(_vm._s(work.name))])],1)}),0)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
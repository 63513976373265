<template>
  <div>
    <vs-popup
      :title="$t('Treatment_Credits')"
      :active.sync="popupActiveTreatment"
    >
      <treatments />
    </vs-popup>
    <div class="grid grid-cols-12">
      <div
        :style="
          getWorkToDo.credit == 0
            ? 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.6);'
            : 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);'
        "
        class="
          col-span-12
          xs:col-span-12
          md:col-span-8
          xl:col-span-4
          jobCols
          m-1
          p-2
        "
      >
        <div class="flex justify-between content-center">
          <p class="tags p-2">{{ $t("Crowns_Offsets") }}</p>
          <vs-icon
            class="mt-2 mr-2"
            style="cursor: pointer"
            icon="info"
            size="20px"
            @click="popupActiveTreatment = true"
          ></vs-icon>
        </div>
        <div
          class="
            grid grid-cols-2
            xs:grid-cols-1
            sm:grid-cols-2
            xl:grid-cols-1
            xxl:grid-cols-2
          "
        >
          <div class="col-span-1 p-2">
            <div
              :class="
                works[0].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #80004d; color: white"
              @click="requestChangeWorkToDo(works[0])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/anatomik-kron.png"
                alt=""
              />
              {{ works[0].name }}
            </div>
          </div>
          <div class="col-span-1 p-2">
            <div
              :class="
                works[1].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #d071a9; color: white"
              @click="requestChangeWorkToDo(works[1])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/anatomik-altyapi.png"
                alt=""
              />
              {{ works[1].name }}
            </div>
          </div>
          <div class="col-span-1 p-2">
            <div
              :class="
                works[2].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #a926b9; color: white"
              @click="requestChangeWorkToDo(works[2])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/kapson-altyapi.png"
                alt=""
              />
              {{ works[2].name }}
            </div>
          </div>
        </div>
        <p class="tags p-2">{{ $t("Pontics") }}</p>
        <div
          class="
            grid grid-cols-2
            xs:grid-cols-1
            sm:grid-cols-2
            xl:grid-cols-1
            xxl:grid-cols-2
          "
        >
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[3].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #0a890a; color: white"
              @click="requestChangeWorkToDo(works[3])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/anatomik-ara-govde.png"
                alt=""
              />
              {{ works[3].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[4].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #00b705; color: white"
              @click="requestChangeWorkToDo(works[4])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/ara-govde-altyapi.png"
                alt=""
              />
              {{ works[4].name }}
            </div>
          </div>
        </div>
        <p class="tags p-2">{{ $t("Inley_Onley") }}</p>
        <div
          class="
            grid grid-cols-2
            xs:grid-cols-1
            sm:grid-cols-2
            xl:grid-cols-1
            xxl:grid-cols-2
          "
        >
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[5].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #1362f2; color: white"
              @click="requestChangeWorkToDo(works[5])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/inlay-dolgu.png"
                alt=""
              />
              {{ works[5].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[6].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #1250be; color: white"
              @click="requestChangeWorkToDo(works[6])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/altyapi-dolgu.png"
                alt=""
              />
              {{ works[6].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[7].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #5027fc; color: white"
              @click="requestChangeWorkToDo(works[7])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/vener.png"
                alt=""
              />
              {{ works[7].name }}
            </div>
          </div>
        </div>
        <p class="tags p-2">{{ $t("Basic_Units") }}</p>
        <div
          class="
            grid grid-cols-2
            xs:grid-cols-1
            sm:grid-cols-2
            xl:grid-cols-1
            xxl:grid-cols-2
          "
        >
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[8].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #a24807; color: white"
              @click="requestChangeWorkToDo(works[8])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/bar-ana-govde.png"
                alt=""
              />
              {{ works[8].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[9].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #e8731f; color: white"
              @click="requestChangeWorkToDo(works[9])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/bar-ara-govde.png"
                alt=""
              />
              {{ works[9].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[10].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #022e3d; color: white"
              @click="requestChangeWorkToDo(works[10])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/surgu.png"
                alt=""
              />
              {{ works[10].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[11].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #b87471; color: white"
              @click="requestChangeWorkToDo(works[11])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/teleskop.png"
                alt=""
              />
              {{ works[11].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[12].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #95080f; color: white"
              @click="requestChangeWorkToDo(works[12])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/all-on-x-ana-govde.png"
                alt=""
              />
              {{ works[12].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[13].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #d34d53; color: white"
              @click="requestChangeWorkToDo(works[13])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/all-on-x-ara-govde.png"
                alt=""
              />
              {{ works[13].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[14].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #05555f; color: white"
              @click="requestChangeWorkToDo(works[14])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/hibrit-ana-govde.png"
                alt=""
              />
              {{ works[14].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[15].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #179bad; color: white"
              @click="requestChangeWorkToDo(works[15])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/hibrit-ara-govde.png"
                alt=""
              />
              {{ works[15].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[16].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #60521b; color: white"
              @click="requestChangeWorkToDo(works[16])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/toronto-ana-govde.png"
                alt=""
              />
              {{ works[16].name }}
            </div>
          </div>
          <div class="sm:col-span-1 p-2">
            <div
              :class="
                works[17].name == getWorkToDo.name || getWorkToDo.name == ''
                  ? ''
                  : 'disabledButton'
              "
              class="vs-buttonCss flex items-center"
              style="background-color: #9f9675; color: white"
              @click="requestChangeWorkToDo(works[17])"
            >
              <img
                class="mr-2"
                src="@/assets/images/dental/job_icons/toronto-ara-govde.png"
                alt=""
              />
              {{ works[17].name }}
            </div>
          </div>
        </div>
      </div>
      <div
        :style="
          getWorkToDo.credit != 0 && materiel == null
            ? 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.6);'
            : 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);'
        "
        :class="getWorkToDo.credit == 0 ? 'disabledDiv' : ''"
        class="
          col-span-12
          xs:col-span-12
          md:col-span-4
          xl:col-span-3
          jobCols
          m-1
          p-2
        "
      >
        <p class="tags text-center mb-6">{{ $t("Material_Selection") }}</p>
        <div class="text-center">
          <div class="vx-row items-end justify-center">
            <div class="vx-col" :class="materiel == 2 ? 'disabledButton' : ''">
              <img
                style="cursor: pointer"
                class="m-4 -mb-2"
                src="@/assets/images/dental/3d-print-active.png"
                alt=""
                @click="
                  materiel = 1;
                  changeMateriel();
                "
              />
              <div>
                <vs-button
                  color="#000"
                  @click="
                    materiel = 1;
                    changeMateriel();
                  "
                >
                  {{ $t("3D_Print") }}
                </vs-button>
              </div>
            </div>

            <div class="vx-col" :class="materiel == 1 ? 'disabledButton' : ''">
              <img
                style="cursor: pointer"
                class="m-4 -mb-2"
                src="@/assets/images/dental/zirkon-active.png"
                alt=""
                @click="
                  materiel = 2;
                  changeMateriel();
                "
              />
              <div class="">
                <vs-button
                  color="#000"
                  @click="
                    materiel = 2;
                    changeMateriel();
                  "
                >
                  {{ $t("Zirkon") }}
                </vs-button>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 justify-items-center">
            <v-select
              style="max-width: 200px; min-width: 160px"
              class="mt-4"
              placeholder="Implant Selection"
              v-model="hasImplant"
              :options="implantOptions"
            />
            <div
              v-if="hasImplant.id == true"
              class="p-2 text-left justify-start"
            >
              <vs-input
                class="mb-2"
                :placeholder="$t('Brand')"
                v-model="implantBrand"
                @change="sendImplantChangeRequest()"
              />
              <vs-input
                :placeholder="$t('Model')"
                v-model="implantModel"
                @change="sendImplantChangeRequest()"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        :style="
          materiel == null
            ? 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);'
            : 'box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.6);'
        "
        :class="materiel == null ? 'disabledDiv' : ''"
        class="col-span-12 xs:col-span-12 xl:col-span-5 jobCols m-1 p-2"
      >
        <div class="flex justify-between">
          <p class="tags">{{ $t("Treatment_Definition") }}</p>
          <p class="tags credit">{{ $t("Credit") }}: {{ totalCredit() }}</p>
        </div>

        <div class="grid grid-cols-12" style="position: relative">
          <div
            style="z-index: 99"
            :class="controlMaterielAndImpland() ? '' : 'disabledDiv'"
            class="
              col-span-8
              xs:col-span-12
              sm:col-span-8
              md:col-span-8
              xl:col-span-12
              xxl:col-span-8
            "
          >
            <dentalSvg />
          </div>
          <div
            class="
              col-span-4
              xs:col-span-12
              sm:col-span-4
              md:col-span-4
              xl:col-span-12
              xxl:col-span-4
              flex
              items-center
            "
            style="height: 50%"
            v-if="treatmentAreaRight"
          >
            <vs-col>
              <vs-row
                class="w-full mb-2"
                vs-type="flex"
                vs-align="center"
                vs-justify="start"
                v-for="work in getSelectedWorks"
                :key="work.code"
              >
                <vs-icon
                  size="20px"
                  :bg="work.color"
                  :color="work.color"
                ></vs-icon>

                <p class="ml-2 teethShowWork">{{ work.name }}</p>
              </vs-row>
            </vs-col>
          </div>
          <div v-else class="stackDiv flex items-center justify-center">
            <vs-col style="width: fit-content">
              <div
                class="flex justify-start mb-2"
                v-for="work in getSelectedWorks"
                :key="work.code"
              >
                <vs-icon
                  size="20px"
                  :bg="work.color"
                  :color="work.color"
                ></vs-icon>

                <p class="ml-2 teethShowWork">{{ work.name }}</p>
              </div>
            </vs-col>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dentalSvg from "./DentalSvg.vue";
import vSelect from "vue-select";

import treatments from "./Treatments";

export default {
  data() {
    return {
      treatmentAreaRight: true,
      popupActiveTreatment: false,
      componentName: "JobSelection",
      hasImplant: { label: this.$t("Has_Not_Implant"), id: false },
      implantOptions: [
        { label: this.$t("Has_Not_Implant"), id: false },
        { label: this.$t("Has_Implant"), id: true },
      ],
      implantBrand: "",
      implantModel: "",
      materiel: null,
    };
  },
  computed: {
    getWorkToDo() {
      return this.$store.getters["dental/getWorkToDo"];
    },
    getSelectedWorks() {
      return this.$store.getters["dental/getSelectedWorks"];
    },
    getOrder() {
      return this.$store.getters["dental/getDentalOrder"];
    },
    getDifferentWorks() {
      return this.$store.getters["dental/getDifferentWorks"];
    },
    works() {
      return this.$store.getters["dental/getDentalWorks"];
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    setTreatmentsArea() {
      if (this.windowWidth >= 1920) {
        this.treatmentAreaRight = true;
      } else if (this.windowWidth >= 1200 && this.windowWidth < 1920) {
        this.treatmentAreaRight = false;
      } else if (this.windowWidth < 1200 && this.windowWidth > 576) {
        this.treatmentAreaRight = true;
      } else {
        this.treatmentAreaRight = false;
      }
    },
    totalCredit() {
      var total = 0;

      this.getDifferentWorks.forEach((element) => {
        total += element.credit * element.unit;
      });

      return total;
    },
    controlMaterielAndImpland() {
      if (this.materiel != null && this.hasImplant.id != true) {
        return true;
      } else if (
        this.hasImplant.id == true &&
        this.implantBrand != "" &&
        this.implantModel != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    changeMateriel() {
      this.$store.dispatch("dental/changeMateriel", this.materiel);
    },
    sendImplantChangeRequest() {
      var implant = {
        implant: this.hasImplant.id,
        brand: this.implantBrand,
        model: this.implantModel,
      };

      this.$store.dispatch("dental/changeImplant", implant);
    },
    requestChangeWorkToDo(work) {
      this.hasImplant = { label: this.$t("Has_Not_Implant"), id: false };
      this.implantBrand = "";
      this.implantModel = "";
      this.materiel = null;
      this.$store.dispatch("dental/changeWordToDo", work);
    },
  },
  destroyed() {
    this.$store.dispatch("dental/changeWordToDo", {
      code: "",
      color: "",
      credit: 0,
      name: "",
    });

    this.$store.dispatch("dental/changeImplant", {
      implant: false,
      brand: "",
      model: "",
    });
    this.$store.dispatch("dental/changeMateriel", {
      materiel: null,
    });
  },
  created() {
    this.setTreatmentsArea();
  },
  components: {
    dentalSvg,
    vSelect,
    treatments,
  },
  watch: {
    hasImplant() {
      this.sendImplantChangeRequest();
    },
    windowWidth() {
      this.setTreatmentsArea();
    },
  },
};
</script>

<style scoped>
.stackDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.disabledDiv {
  opacity: 0.4 !important;
  pointer-events: none !important;
}
.disabledButton {
  opacity: 0.4 !important;
}

.vs-buttonCss {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 0.75rem 1rem;
}
.jobCols {
  background-color: white;
  border-radius: 0.5rem;
}
.tags {
  color: #808080;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.credit {
  font: normal normal bold 20px/25px Helvetica Neue;
}
.teethShowWork {
  font: normal normal normal 16px/17px Roboto;
  color: #707070;
}
</style>

<style>
.v-select .vs__dropdown-toggle .vs__actions .vs__clear {
  display: none;
}
</style>

<template>
  <div>
    <!-- FILE UPLOAD  -->
    <vs-row
      class="w-full mb-2"
      vs-type="flex"
      vs-align="center"
      vs-justify="space-between"
    >
      <div>
        <input
          :disabled="!fileUploadEnable"
          :class="{
            inputButton: fileUploadEnable,
            inputButtonDisabled: !fileUploadEnable,
          }"
          multiple
          accept=".zip, .pdf, .png"
          type="file"
          id="files"
          ref="files"
          v-on:change="handleFileUpload"
        />
        <label class="ml-2" for="files">{{ $t("Add_File") }}</label>
      </div>
    </vs-row>

    <!-- NEW FILES  -->
    <vs-row>
      <fileComp
        class="p-2"
        :key="file.name"
        v-for="file in files"
        :file="file"
        @uploadedFile="addFilesTheOrder($event)"
      />
    </vs-row>

    <h2 v-if="getOrderFiles.length" class="p-2">{{ $t("Attached_Files") }}</h2>

    <!-- OLD FILES  -->
    <vs-row>
      <div class="p-1 w-full" :key="file.id" v-for="file in getOrderFiles">
        <fileList
          :file="file"
          @selectFile="selectFile($event)"
          :removeAvaible="true"
          @fileRemovedFromOrder="fileRemovedFromOrder($event)"
        />
      </div>
    </vs-row>

    <h2 class="p-2">{{ $t("Unattached_Files") }}</h2>
    <!-- NOT ASSIGN FILES -->
    <vs-row vs-type="flex" vs-align="center">
      <div class="p-1 w-full" :key="file.id" v-for="file in fileList">
        <fileList
          :file="file"
          @selectFile="addFilesTheOrder($event)"
          :selectAvaible="true"
          @fileDeleted="fileDeleted($event)"
        />
      </div>
    </vs-row>
    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="handleChangePage()"
      />
    </div>
  </div>
</template>

<script>
import API from "@/api/config";
import Api from "@/api/api";

import axios from "axios";

import fileComp from "./File";
import fileList from "./FileList";

export default {
  data() {
    return {
      fileList: [],
      selectedFiles: [],
      fileUploadEnable: true,
      files: "",
      fileCounter: 0,

      currentPage: 1,
      totalPage: 1,
    };
  },
  methods: {
    handleChangePage() {
      this.getFiles();
    },
    fileDeleted(id) {
      const index = this.fileList.findIndex((i) => i.id == id);
      this.fileList.splice(index, 1);
    },
    fileRemovedFromOrder(id) {
      const index = this.getOrderFiles.findIndex((i) => i.id == id);
      this.getOrderFiles.splice(index, 1);
      this.getFiles();
    },
    sendFiles() {
      if (this.selectedFiles.length == 0 && this.getOrderFiles.length == 0) {
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("Dental_Message_14"),
        });
        return;
      }
      if (this.selectedFiles.length == 0) {
        this.$emit("nextTab");
        return;
      }
      var params = {
        method: "append",
        files: this.selectedFiles,
      };

      Api.put(
        API.BASEURL + API.DENTALORDERS + this.getOrder.id + "/files/",
        this.handleSendFiles,
        params
      );
    },
    handleSendFiles(status, data) {
      this.$emit("nextTab");
    },
    addFilesTheOrder(file) {
      if (this.fileCounter != 1) {
        this.fileCounter--;
      } else {
        this.fileUploadEnable = true;
        this.files = "";
      }
      var params = {
        method: "append",
        files: [file],
      };

      Api.put(
        API.BASEURL + API.DENTALORDERS + this.getOrder.id + "/files/",
        this.handleAddFilesTheOrder,
        params
      );
    },
    handleAddFilesTheOrder(status, data) {
      if ((status) => 200 && status < 300) {
        this.getOrderDetail();
        this.getFiles();
      }
    },
    handleFileUpload() {
      this.files = this.$refs.files.files;
      this.fileUploadEnable = false;
      this.fileCounter = this.files.length;
    },
    getOrderDetail() {
      Api.get(
        API.BASEURL + API.DENTALORDERS + this.getOrder.id + "/",
        this.handleGetOrderDetail
      );
    },
    handleGetOrderDetail(status, data) {
      if (status == 200) {
        this.$store.dispatch("dental/changeOrder", data);
      }
    },
    selectFile(id) {
      const index = this.selectedFiles.findIndex((i) => i == id);

      if (index == -1) {
        this.selectedFiles.push(id);
      } else {
        this.selectedFiles.splice(index, 1);
      }
    },
    getFiles() {
      var params = {};

      if (this.currentPage != 1) {
        params.id = this.getOrder.id;
        params.page = this.currentPage;
        this.$router.push({ query: params }).catch((error) => {
          if (error.name != "NavigaticonDuplicated") {
            throw error;
          }
        });
      }

      Api.get(API.BASEURL + API.DENTAL_FILES, this.handleGetFiles);
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
    },
    handleGetFiles(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        this.fileList = data.results;
        this.totalPage = Math.ceil(data.count / 20);
      }
    },
  },
  created() {
    if (this.$route.query.page != null && this.$route.query.page != "1") {
      this.currentPage = parseInt(this.$route.query.page);
    }
    this.getFiles();
  },
  components: {
    fileComp,
    fileList,
  },
  computed: {
    getOrder() {
      return this.$store.getters["dental/getDentalOrder"];
    },
    getOrderFiles() {
      return this.$store.getters["dental/getOrderFiles"];
    },
  },
};
</script>

<style scoped>
.inputButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputButton + label {
  color: rgba(var(--vs-primary), 1);
  border: 1px solid rgba(var(--vs-primary), 1);
  background: transparent !important;
  display: inline-block;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
.inputButtonDisabled {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputButtonDisabled + label {
  color: rgba(var(--vs-primary), 1);
  border: 1px solid rgba(var(--vs-primary), 1);
  background: transparent !important;
  opacity: 0.5;
  display: inline-block;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
</style>

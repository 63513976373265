<template>
  <div class="mt-8">
    <div
      class="
        grid grid-cols-12
        xs:grid-cols-6
        md:grid-cols-8
        lg:grid-cols-10
        xl:grid-cols-12
        mb-2
      "
    >
      <div class="flex col-span-12 sm:col-span-2">
        <p class="flex items-center tags">
          {{ $t("Patient") }} {{ $t("Name") }} / {{ $t("Reference") }}
        </p>
      </div>
      <div class="col-span-12 sm:col-span-4">
        <vs-input
          v-on:keypress="isLetter($event)"
          class="w-full"
          v-model="patient"
          @click="errorActive = false"
        />
      </div>
    </div>
    <div
      v-if="labOptions.length > 1"
      class="
        grid grid-cols-12
        xs:grid-cols-6
        md:grid-cols-8
        lg:grid-cols-10
        xl:grid-cols-12
        mb-2
      "
    >
      <div class="flex col-span-12 sm:col-span-2">
        <p class="flex items-center tags">{{ $t("Laboratory") }}</p>
      </div>
      <div class="col-span-12 sm:col-span-4">
        <v-select
          style=""
          v-model="selectedLab"
          :options="labOptions"
          @input="hasEdit = true"
        />
      </div>
    </div>
    <div
      class="
        grid grid-cols-12
        xs:grid-cols-6
        md:grid-cols-8
        lg:grid-cols-10
        xl:grid-cols-12
        mb-2
      "
    >
      <div class="flex col-span-12 sm:col-span-2">
        <p class="flex items-start tags">{{ $t("Note") }}</p>
      </div>
      <div class="col-span-12 sm:col-span-4">
        <vs-textarea v-model="note" @input="hasEdit = true" />
      </div>
    </div>
    <div class="grid grid-cols-12">
      <div
        class="
          col-span-12
          xs:col-span-12
          md:col-span-9
          lg:col-span-7
          xl:col-span-6
        "
      >
        <vs-alert :active="errorActive" color="danger">
          {{ errorMessage }}
        </vs-alert>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import API from "@/api/config";
import Api from "@/api/api";

export default {
  data() {
    return {
      hasEdit: false,
      selectedLab: null,
      labOptions: [],
      patient: "",
      note: "",
      checkCreateRequest: 0,
      errorActive: false,
      errorMessage: "",
    };
  },
  components: {
    vSelect,
  },
  methods: {
    controlNextPage() {
      if (this.patient == "") {
        this.errorMessage = this.$t("Dental_Message_7");
        this.errorActive = true;
        return false;
      }

      if (this.labOptions.length == 0) {
        this.errorMessage = this.$t("Dental_Message_8");
        this.errorActive = true;
        return;
      } else if (this.labOptions.length == 1) {
        this.selectedLab = this.labOptions[0];
      } else if (this.labOptions.length > 1 && this.selectedLab == null) {
        this.errorMessage = this.$t("Dental_Message_9");
        this.errorActive = true;
        return false;
      }

      var params = {
        patient: this.patient,
        lab: this.selectedLab.id,
        note: this.note,
      };

      if (this.hasEdit && this.getOrder.id == null) {
        Api.post(
          API.BASEURL + API.DENTALORDERS,
          this.handleControlNextPage,
          params
        );
      } else if (!this.hasEdit && this.getOrder.id) {
        this.$emit("nextTab");
      } else {
        Api.put(
          API.BASEURL + API.DENTALORDERS + this.getOrder.id + "/",
          this.handleControlNextPage,
          params
        );
      }
    },
    handleControlNextPage(status, data) {
      if (status >= 200 && status < 300) {
        this.hasEdit = false;
        this.$router.push({ query: { id: data.id } }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
        this.$emit("nextTab");
        this.$store.dispatch("dental/changeOrder", data);
      } else if (status == 400) {
        this.errorMessage = this.$t("Dental_Message_11");
        this.errorActive = true;
      }
    },

    getLabs() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(API.BASEURL + API.DENTALLABS, this.handleGetLabs);
    },
    handleGetLabs(status, data) {
      if (status == 200) {
        this.labOptions = data.results;
        this.$vs.loading.close();
      } else if (status == 0) {
        this.$vs.loading.close();
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("Dental_Message_12"),
        });
      }
    },
    isLetter(e) {
      this.hasEdit = true;
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-zıİğĞüÜöÖçÇşŞ ]+$/.test(char)) return true;
      else e.preventDefault();
    },
    initPage() {
      if (this.getOrder.patient != "") {
        this.patient = this.getOrder.patient;
        this.selectedLab = {
          id: this.getOrder.lab,
          label: this.getOrder.lab_display,
        };
        this.note = this.getOrder.note;
      }
    },
  },
  computed: {
    getOrder() {
      return this.$store.getters["dental/getDentalOrder"];
    },
    getOrderPatient() {
      return this.$store.state.dental.order.patient;
    },
  },
  watch: {
    getOrderPatient() {
      this.initPage();
    },
  },
  created() {
    this.getLabs();
    this.initPage();
  },
};
</script>

<style>
.vs__dropdown-option--highlight {
  background: #c89e82 !important;
  color: #fff;
}
</style>

<style scoped>
.tags {
  color: #808080;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
</style>
